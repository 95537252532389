import * as React from 'react';
import { MDXProvider } from '@mdx-js/react';
import SiteHeader from '@/components/SiteHeader';
import { SiteFooter } from '@/components/SiteFooter';
import styled from '@emotion/styled';
import { SectionContainer } from './SectionContainer';
import { SectionContent } from './SectionContent';
import { HeroHeaderSection, HeroHeaderTitle } from '../HeroHeader';

const Main = styled.main`
  background-color: #ffffff;
  color: black;
`;

const MdSection = styled(SectionContent)`
  display: block;
`;

const shortcodes = {
  h2: styled.h2`
    margin-top: 32px;
    border-bottom: 3px solid #dadada;
  `,
};

type PageProps = React.PropsWithChildren<{
  pageContext: { frontmatter: { title: string } };
}>;

const Page = ({ children, pageContext }: PageProps) => {
  return (
    <>
      <SiteHeader />
      <Main>
        <HeroHeaderSection>
          <HeroHeaderTitle>{pageContext.frontmatter.title}</HeroHeaderTitle>
        </HeroHeaderSection>
        <SectionContainer>
          <MdSection>
            <MDXProvider components={shortcodes}>{children}</MDXProvider>
          </MdSection>
        </SectionContainer>
      </Main>
      <SiteFooter />
    </>
  );
};

export default Page;
