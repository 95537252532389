import * as React from 'react';
import styled from '@emotion/styled';
import { Section } from '@/components/layout/Section';
import * as colors from '@/components/colors';
import { size } from '@/components/mq';

export const HeroHeaderSection = styled(Section)`
  background: linear-gradient(
    to top right,
    ${colors.primary},
    ${colors.secondary}
  );
  padding-top: 16px;
  padding-bottom: 16px;
  color: #fff;

  ${size.small} {
    /* padding: 16px; */
  }

  ${size.medium} {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`;

export const HeroHeaderTitle = styled.h1`
  font-family: 'Tommy Soft', 'Open Sans';
  margin: 0;
  font-size: 32px;
  font-weight: 800;
  /* font-style: italic; */
  text-transform: uppercase;
  line-height: 32px;

  ${size.medium} {
    /* margin: 56px 0; */
    font-size: 56px;
    line-height: 56px;
  }
`;

export const HeroHeaderSubtitle = styled.p`
  margin: 24px 0 4px;
  font-size: 18px;
  font-weight: bold;
  font-style: italic;

  ${size.medium} {
    font-size: 24px;
  }
`;
