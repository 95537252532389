import React from 'react';
import { Global, css } from '@emotion/react';
import { Helmet } from 'react-helmet';
import * as colors from '../colors';

export default function Layout({ children }) {
  return (
    <>
      <Helmet>
        <html lang="en" />
      </Helmet>
      <Global
        styles={css`
          html,
          body {
            box-sizing: border-box;
            padding: 0;
            margin: 0;

            font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial',
              sans-serif;

            background-color: ${colors.background};
            color: white;
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-family: 'Tommy Soft', 'Open Sans', 'Helvetica Neue',
              'Helvetica', 'Arial', sans-serif;
            font-weight: bold;
          }

          h1 {
            font-size: 48px;
          }

          h2 {
            font-size: 32px;
          }

          a {
            color: ${colors.primary};
            font-weight: bold;
          }
        `}
      />
      {children}
    </>
  );
}
