import * as React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

import logo from '@/images/double-turn/double-turn-logo.png';
import Layout from '@/components/layout/Layout';
import * as colors from '@/components/colors';
import { size } from './mq';

const headerHeight = 120;
const smallHeaderHeight = 100;

const Header = styled.header`
  display: flex;
  justify-content: center;
  background-color: ${colors.background};
  height: ${smallHeaderHeight}px;

  ${size.medium} {
    height: ${headerHeight}px;
  }
`;

const FullWidthColumn = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  max-width: 1100px;
  padding: 0 16px;
`;

const SiteLogoLink = styled(Link)`
  display: flex;
  align-items: center;

  img {
    height: 86px;
    width: auto;

    ${size.medium} {
      height: 86px;
    }
  }

  &:focus {
    background-color: ${colors.backgroundLight};
  }

  &:hover,
  &:focus {
    box-shadow: inset 0 0 0 2px #148eff;
  }
`;

const NavActiveLink = styled(Link)`
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0;
  font-size: 28px;

  ${size.medium} {
    padding: 0 8px;
    font-size: 16px;
  }

  span {
    position: relative;
  }

  span::after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0%;
    border-bottom: 3px solid #148eff;
    transition: 0.4s;
  }

  &:focus {
    background-color: ${colors.backgroundLight};
  }

  &:hover span::after,
  &:focus span::after {
    width: 100%;
  }

  &.active {
    span::after {
      width: 100%;
    }
  }
`;
const NavLink = (props) => (
  <NavActiveLink activeClassName="active" {...props} />
);

export const SiteHeader = () => {
  const [navOpen, setNavOpen] = React.useState(false);

  return (
    <Layout>
      <Header>
        <FullWidthColumn>
          <SiteLogoLink to="/" tabIndex={0}>
            <img src={logo} alt="Double Turn Logo" width="302" height="48" />
          </SiteLogoLink>
        </FullWidthColumn>
      </Header>
    </Layout>
  );
};

export default SiteHeader;
