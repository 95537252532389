export const background = '#151C27';
export const backgroundLight = '#263855';
export const primary = '#148EFF';
export const primaryDarker = '#0074E0';
export const primaryDarkest = '#0057A8';
export const secondary = '#2AEDFA';
export const brands = {
  itch: '#FF2449',
  steam: '#000000',
};
