// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-tsx": () => import("./../../../src/pages/apps.tsx" /* webpackChunkName: "component---src-pages-apps-tsx" */),
  "component---src-pages-games-tsx": () => import("./../../../src/pages/games.tsx" /* webpackChunkName: "component---src-pages-games-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inwave-labs-tsx": () => import("./../../../src/pages/inwave-labs.tsx" /* webpackChunkName: "component---src-pages-inwave-labs-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-patch-notes-mdx": () => import("./../../../src/pages/patch-notes.mdx" /* webpackChunkName: "component---src-pages-patch-notes-mdx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-presskit-tsx": () => import("./../../../src/pages/presskit.tsx" /* webpackChunkName: "component---src-pages-presskit-tsx" */)
}

