import styled from '@emotion/styled';

import * as colors from '@/components/colors';

export const NewsletterForm = styled.form`
  background: linear-gradient(
    to top right,
    ${colors.backgroundLight},
    ${colors.background}
  );
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  grid-area: newsletter;
  align-self: start;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  color: #ffffff;

  label {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 2px;
  }

  input {
    border-radius: 4px;
    border: none;
    font-size: 18px;
    padding: 6px;
    margin-bottom: 16px;
  }

  input[type='submit'] {
    background-color: ${colors.primary};
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    display: inline-flex;
    height: 40px;
    justify-content: center;
    align-self: flex-start;
    padding: 0 16px;

    &:hover,
    &:focus {
      background-color: ${colors.primaryDarker};
      transition: 0.4s;
    }
  }

  .powered-by-bd {
    font-size: 14px;
    a {
      color: #ffffff;
    }
  }
`;

export const FormTitle = styled.div`
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 16px;
`;
