import * as React from 'react';
import { SectionContainer } from './SectionContainer';
import { SectionContent } from './SectionContent';

type SectionProps = React.PropsWithChildren<{
  as?: React.ElementType<any>;
}>;

export const Section = ({ children, ...props }: SectionProps) => (
  <SectionContainer {...props}>
    <SectionContent>{children}</SectionContent>
  </SectionContainer>
);
