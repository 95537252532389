import * as React from 'react';
import styled from '@emotion/styled';

import { Section } from '@/components/layout/Section';
import * as colors from '@/components/colors';
import logo from '@/images/inwavelabs-logo-white.svg';
import { discord, twitter, youtube } from '@/images/icons';
import { size } from './mq';
import { NewsletterSection } from './sections/NewsletterSection';

const NEWSLETTER_DISABLED = true;

const Footer = styled(Section)`
  > div {
    display: grid;
    grid-template-areas: 'contact' 'social' 'copyright';
    justify-items: center;
    column-gap: 16px;
    row-gap: 16px;
    padding: 16px;
  }

  ${size.medium} {
    > div {
      justify-items: stretch;
      grid-template-areas: 'contact' 'social' 'copyright';
      grid-template-columns: 400px 1fr;
      grid-template-rows: auto 1fr;
    }
  }

  ${size.large} {
    > div {
      grid-template-areas: 'contact social' 'copyright copyright';
      grid-template-columns: 1fr auto;
    }
  }

  background-color: ${colors.primary};
  color: #ffffff;
`;

const ContactList = styled.div`
  margin: 16px 0;

  ul {
    padding-left: 0;
    list-style: none;
    margin: 4px 0;
    li {
      a {
        color: #ffffff;
      }
    }
  }
`;

const ContactListTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
`;

const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  > :not(:first-child) {
    margin-left: 16px;
  }
`;

const Copyright = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  margin-top: 16px;
  padding-top: 12px;
  grid-area: copyright;
  justify-self: stretch;
  font-size: 14px;

  ${size.medium} {
    flex-direction: row;
    justify-content: space-between;
  }

  a {
    color: #ffffff;
    font-weight: bold;
  }
`;

interface Props {
  hideNewsletter?: boolean;
}

export const SiteFooter = (props: Props) => (
  <footer>
    {!NEWSLETTER_DISABLED && !props.hideNewsletter && <NewsletterSection />}
    <Footer>
      <div
        style={{
          gridArea: 'contact',
        }}
      >
        {/* <img
          src={logo}
          alt="Inwave Labs Logo"
          height="30"
          style={{
            gridArea: 'logo',
          }}
        /> */}
        <ContactList>
          <ContactListTitle>✉️ Say Hello</ContactListTitle>
          <ul>
            <li>
              <strong>Contact:</strong>{' '}
              <a href="mailto:contact@inwavelabs.com">hello@inwavelabs.com</a>
            </li>
            <li>
              <strong>Press:</strong>{' '}
              <a href="mailto:press@inwavelabs.com">press@inwavelabs.com</a>
            </li>
            <li>
              <strong>Support:</strong>{' '}
              <a href="mailto:support@inwavelabs.com">support@inwavelabs.com</a>
            </li>
          </ul>
        </ContactList>
      </div>
      {/* <SocialLinks>
        <a href="https://youtube.com">
          <img src={youtube} alt="YouTube" height="32" />
        </a>
        <a href="https://discord.gg">
          <img src={discord} alt="Discord" height="32" />
        </a>
        <a href="https://twitter.com/inwavelabs">
          <img src={twitter} alt="Twitter" height="32" />
        </a>
      </SocialLinks> */}
      <Copyright>
        <span>
          <a href="https://inwavelabs.com">Inwave Labs, LLC</a> © 2017-2023
        </span>
        <span>
          Made with ❤ by <a href="https://kennygoff.com">Kenny Goff</a>
        </span>
      </Copyright>
    </Footer>
  </footer>
);
