const breakpoints = [800, 1132, 1600];

const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);

export const size = {
  small: `@media (max-width: ${breakpoints[0] - 1}px)`,
  medium: mq[0],
  large: mq[1],
  xlarge: mq[2],
};
