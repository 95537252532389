/** @jsx jsx */
import { jsx } from '@emotion/react';
import styled from '@emotion/styled';

import { FormTitle, NewsletterForm } from '@/components/NewsletterForm';
import { SectionContainer } from '@/components/layout/SectionContainer';
import { SectionContent } from '@/components/layout/SectionContent';
import { size } from '@/components/mq';
import { primary, primaryDarker, primaryDarkest } from '../colors';

const NewsletterContent = styled(SectionContent)`
  padding: 16px;
  color: #ffffff;
  display: grid;
  grid-template-areas: 'details' 'form';
  grid-template-columns: auto;
  grid-template-rows: auto;
  /* grid-gap: 16px;  */

  ${size.medium} {
    grid-template-areas: 'details form';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    align-items: center;
  }
`;

const NewsletterDetails = styled.div`
  font-size: 18px;
  grid-area: details;
  padding: 16px;

  ${size.medium} {
    padding: 16px 16px 16px 0;
  }

  .title {
    display: block;
    margin: 0 0 16px 0;
    font-size: 24px;
    font-weight: bold;
    font-family: 'Tommy Soft';
  }

  p {
    margin: 0 0 16px 0;
  }
`;

export const NewsletterSection = () => (
  <SectionContainer css={{ backgroundColor: primaryDarkest }}>
    <NewsletterContent>
      <div>
        <NewsletterForm
          action="https://buttondown.email/api/emails/embed-subscribe/inwavelabs"
          method="post"
          target="popupwindow"
          onSubmit={() =>
            window.open('https://buttondown.email/inwavelabs', 'popupwindow')
          }
          className="embeddable-buttondown-form"
          css={{ gridArea: 'form' }}
        >
          <FormTitle>Join the newsletter!</FormTitle>
          <label htmlFor="bd-email">Email</label>
          <input type="email" name="email" id="bd-email" />
          <input type="hidden" value="1" name="embed" />
          <input type="submit" value="Subscribe" />
          <div className="powered-by-bd">
            Powered by <a href="https://buttondown.email">Buttondown</a>
          </div>
        </NewsletterForm>
      </div>
      <NewsletterDetails>
        <div className="title">💌 The Inwave Newsletter</div>
        <p>
          News, updates, and development logs directly from your friendly
          neighborhood indie developer. Plus bonus goodies, tips and tricks on
          our games, and exclusive previews you won’t find on our Twitter feed.
        </p>
        <p>It’s like a cool club for cool folks who like what we do.</p>
      </NewsletterDetails>
    </NewsletterContent>
  </SectionContainer>
);
